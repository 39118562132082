import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DeskManagerHelperService} from './desk-manager-helper.service';
import { produce } from 'immer';
import { ErrorParseService } from './error-parse.service';
import { LoginHelperService } from './login-helper.service';
import { Organization } from '../models/organization';
import {PlanHelperService} from "./plan-helper.service";
import {NotificationsService} from "./notifications.service";
import {WorkspaceType} from "../interfaces/desk.interface";

enum OrganizationStateKeys {
    MEMBERS_COUNT = "membersCount",
    MAX_MEMBERS_COUNT = "maxMembersCount",
    WORKSPACE_COUNT = "workspaceCount",
    NAME = "name",
    LOADING = "loading",
    ERROR_MSG = "errorMessage",
}

export interface OrganizationState {
    [OrganizationStateKeys.MEMBERS_COUNT]?: number;
    [OrganizationStateKeys.MAX_MEMBERS_COUNT]?: number;
    [OrganizationStateKeys.WORKSPACE_COUNT]?: number;
    [OrganizationStateKeys.NAME]?: string;
    [OrganizationStateKeys.LOADING]?: boolean;
    [OrganizationStateKeys.ERROR_MSG]?: string;
}

@Injectable({
    providedIn: 'root'
})
export class OrganizationStateService {

    private readonly _UNAVAILABLE_MSG = `Plan details are currently unavailable`;
    
    private readonly _UNKNOWN_NAME = `My Organization`;

    private readonly _INITIAL_STATE = {
        [OrganizationStateKeys.LOADING]: true,
        [OrganizationStateKeys.ERROR_MSG]: "",
    };

    private _state: BehaviorSubject<OrganizationState>;
    readonly state$: Observable<OrganizationState>;

    private _cache: OrganizationState;
    private _organization: Organization;
    private _planUpdateSubscription: Subscription;

    constructor(
        private planHelperService: PlanHelperService,
        private workspaceHelperService: DeskManagerHelperService,
        private errorParseService: ErrorParseService,
        private loginHelperService: LoginHelperService,
        private notifier: NotificationsService,
    ) { 
        this._state = new BehaviorSubject<OrganizationState>(this._INITIAL_STATE);
        this.state$ = this._state.asObservable();
        
        this.init();
    }

    init = async (): Promise<void> => {
        if (this.loginHelperService.isUserLoggedIn()) {
            try {
                const [ plan, organization ] = await Promise.all([
                    this.planHelperService.getOrganizationPlansDetails(),
                    this.workspaceHelperService.getOrganizationData()
                ]);
        
                this._organization = organization;
                this._setOrganizationState(plan);
                this._setPlanUpdateSubscription();
    
            } catch (error) {
                this._setState({
                    [OrganizationStateKeys.LOADING]: false,
                    [OrganizationStateKeys.ERROR_MSG]: this._UNAVAILABLE_MSG,
                });
            }
        }
    }

    private _setOrganizationState = (plan: any): void => {
        if (plan) {
            const maxMembersCount = plan.features.maxUsers;
            const membersCount = plan.resourcesUsage.users;
            const workspaceCount = 
                (this._organization.desks || [])
                .filter(desk => desk.type !== WorkspaceType.PERSONAL)
                .length
    
            this._cache = {
                maxMembersCount,
                membersCount,
                workspaceCount,
                name: this._organization.isDefaultName ? this._UNKNOWN_NAME : this._organization.name,
                loading: false,
            };
    
            this._setState(this._cache);
        }
    }

    private _setPlanUpdateSubscription = (): void => {
        this._planUpdateSubscription = this.planHelperService.organizationPlan$
            .subscribe(this._setOrganizationState);
    }

    changeMembersCount = (change: number): void => {
        this._setStateValue(
            OrganizationStateKeys.MEMBERS_COUNT, 
            this._cache[OrganizationStateKeys.MEMBERS_COUNT] + change,
        );
    }

    setWorkspaceCount = (count: number): void => {
        this._setStateValue(OrganizationStateKeys.WORKSPACE_COUNT, count);
    }

    setName = async (newName: string, dontShowChangeNamePrompt?: boolean): Promise<void> => {
        try {
            await this.workspaceHelperService.updateOrganization({
                newName,
                dontShowChangeNamePrompt
            });

            this._setStateValue(OrganizationStateKeys.NAME, newName);

            this.notifier.success("Organization updated successfully");
            
        } catch (error) {
            const parsedError = this.errorParseService.parseWorkspaceErrors(error.responseJSON)
            this.notifier.error(parsedError || "Failed to update organization name");
        }
    }


    clear = (): void => {
        this._state.next(null);
        this._cache = this._INITIAL_STATE;
        this._planUpdateSubscription.unsubscribe();
    }

    private _setStateValue = (key: string, value: any): void => {
        this._setState(produce(this._cache, draft => {
            draft[key] = value;
        }));
    }

    private _setState = (state: OrganizationState): void => {
        this._cache = state;
        this._state.next(state);
    }

}